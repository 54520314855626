import React from "react"
import Layout from "../components/layout";
import TopSection from "../components/topSection";
import ContactForm from "../components/contactForm";
import {graphql, useStaticQuery} from "gatsby";

export default function ContactUs() {
    const data = useStaticQuery(graphql`
      query {
        desktop: file(relativePath: { eq: "contact.jpg" }) {
            childImageSharp {
                fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }`)

    return (
        <Layout title="Contact Us">
            <TopSection image={data.desktop.childImageSharp.fluid} title="GET IN TOUCH"/>
            <section className="py-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h3>SEND US A MESSAGE FOR
                                IF YOU HAVE ONE</h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur venenatis, nisl in
                                bib endum commodo, sapien justo cursus urna, quis porta mauris elit finibus nulla. In
                                vel laci nia felis, quis facilisis enim. Etiam euismod finibus mauris cursus aliquet.
                                Aenean sapien odio, fringilla et neque ac, malesuada mattis magna. Aliquam erat
                                volutpat.
                            </p>
                        </div>
                        <div className="col-md-6">
                            <ContactForm/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}

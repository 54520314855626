import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import styles from './topSection.module.scss'

export default function TopSection({title, image}) {
    return (
        <BackgroundImage
            Tag="section"
            className={styles.bgImage}
            fluid={image}
            backgroundColor={`#040e18`}
        >
            <div className="container h-100">
                <div className="row h-100 align-items-center">
                    <div className="col-xl-10 col-md-12 col-12">
                        <div className="py-13 pt-lg-16 pb-lg-14 text-light">
                            <h1 className="font-weight-bold text-white mb-2">
                                {title}
                            </h1>
                        </div>
                    </div>
                </div>
            </div>
        </BackgroundImage>
    )
}

import React, {useState} from "react"
import styles from "./form.module.scss"
import Button from "./button";

export default function ContactForm() {
    const [status, setStatus] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();
        const form = event.target;
        const data = new FormData(form);
        const xhr = new XMLHttpRequest();
        xhr.open(form.method, form.action);
        xhr.setRequestHeader("Accept", "application/json");
        xhr.onreadystatechange = () => {
            if (xhr.readyState !== XMLHttpRequest.DONE) return;
            if (xhr.status === 200) {
                form.reset();
                setStatus("SUCCESS");
            } else {
                setStatus("ERROR");
            }
        };
        xhr.send(data);
    };

    return (
        <form action="https://formspree.io/mzbjkwya"
              method="POST"
              onSubmit={handleSubmit}
        >
            <div className="form-group">
                <div className="input-group mb-3">
                    <input name="name" id="name" placeholder="Your Name" required
                           className={`form-control ${styles.inputText}`}/>
                </div>
            </div>
            <div className="form-group">
                <div className="input-group mb-3">
                    <input name="email" id="email" placeholder="Your Email" required
                           type="email" className={`form-control ${styles.inputText}`}/>
                </div>
            </div>
            <div className="form-group">
                <textarea rows="4" name="message" id="message"
                          placeholder="Your Message" required
                          className={`form-control ${styles.inputText}`}/>
            </div>
            <Button type="submit" label="Send message"/>
            {status === "SUCCESS" && <p className="my-4">Thank you! Your form submission has been received.</p>}
            {status === "ERROR" && <p>Ooops! There was an error.</p>}
        </form>
    )
}

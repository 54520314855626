import React from "react"
import styles from "./button.module.scss"

export default function Button(props) {
    const className = `${styles.btn} ${props.classType} border-0 position-relative d-inline-block`;
    return (
        <button type={props.type} className={className} onClick={props.handleClick}>
            {props.label}
        </button>
    );
}
